import React, { useRef, useState, useEffect } from 'react';
import './spotlight.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link, useHistory } from 'react-router-dom';

const Spotlight = ({ data = {} }) => {

  const { briefDescription, headline, heroImage, highlight, originalCreationDate, projectTags, slug, subtitle, title  } = data;

  const sectionRef = useRef(null);
  const handleFocus = () => { sectionRef.current.classList.add('spotlight-focus') };
  const handleBlur = () => { sectionRef.current.classList.remove('spotlight-focus') };

  const history = useHistory();
  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      history.push(`/projects/${slug}`);
    }
  };

  const imageUrl = heroImage?.fields?.file?.url;

  const projectLink = `/projects/${slug}`;

  return (
    <section
      ref={sectionRef}
      id={slug}
      className={'container spotlight'}
      tabIndex="0"
      onKeyUp={handleKeyUp}
      onFocus={handleFocus}
      onBlur={handleBlur}
      aria-label={`See more about ${title} ${subtitle}`}
      aria-keyshortcuts="Enter"
    >
      <div className="spotlight__wrapper">
        <div className="spotlight__display">
          <img alt="placeholder" src={imageUrl} />
        </div>
        <div className="spotlight__details">
          <div className="spotlight__copy">
            <h2 className="headline">{headline}</h2>
            <h3 className="subheadline">{subtitle}</h3>
            {documentToReactComponents(briefDescription)}
            {projectTags}
          </div>
          {slug && (
            <Link className="btn btn-secondary btn-md" tabIndex="-1" aria-hidden="true" to={projectLink}>
              See More
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default Spotlight;
