import React from 'react';
import Band from '../components/band/band';
import Cover from '../components/cover/cover';
import Button from '../components/button/button';
import Spotlight from '../components/spotlight/spotlight';
import Card from '../components/card/card';
import Poster from '../components/poster/poster';
import Text from '../components/text/text';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const buildComponents = (pageData) => {

  return pageData.fields?.sections.map((section, i) => {

    const componentType = section?.sys?.contentType?.sys?.id;

    if (componentType === "cover") {
      return (
        <section className='container' key={i}>
          <Cover data={section} />
        </section>
      );
    } 
    
    else if (componentType === "band") {
      return (
        <section className='container' key={i}>
          <Band data={section?.fields}>
            {section?.fields?.sections.map((item, j) => {

              const componentType = item?.sys?.contentType?.sys?.id;

              if (componentType === "spotlight") {
                return (
                  <Spotlight key={j} data={item.fields} />
                );
              } 

              if (componentType === "card") {
                return (
                  <Card key={j} data={item.fields} />
                );
              } 

              if (componentType === "text") {
                return (
                  <Text key={j} data={item.fields}>
                    {documentToReactComponents(item.fields.copy)}
                  </Text>
                );
              } 
              
              if (componentType === "poster") {
                return (
                  <Poster key={j} data={item.fields} />
                );
              } 
              
              if (componentType === "button") {
                return (
                  <Button key={j} data={item.fields} />
                );
              } 
              
              return null;
              
            })}
          </Band>
        </section>
      );
    }
    
    else {
      return (
        <p key={i}>{componentType} did not load</p>
      );
    }
  });

};
