import { createClient } from 'contentful';
import { CONFIG } from './config.js';
import Button from '../components/button/button.js';

const client = createClient({
  space: CONFIG.space, // This is the space ID. A space is like a project folder in Contentful terms
  accessToken: CONFIG.accessToken // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
});

export function fetchContentfulEntries(content_type, order_by, limit) {
  const entries = client.getEntries({
    content_type: content_type,
    order: order_by,
    limit: limit ? limit : 99,
    include: 3
  });
  return entries;
}

export function fetchContentfulAsset(id) {
  const asset = client.getAsset(id);
  return asset;
}

export const contentfulRenderNode = {
  renderNode: {
    'embedded-asset-block': (node) => {
      return <img src={node.data.target.fields.file.url} alt={node.data.target.fields.description} />;
    },
    'embedded-entry-block': (node) => {
      const { id } = node.data.target.sys.contentType.sys;
      if (id === 'button') {
        return (
          <Button data={node.data.target.fields} />
        );
      } else {
        return (
          <div>
            <h2>Unrecognized id: {id || 'N/A'}</h2>
          </div>
        );
      }
    }
  }
};
