import React, { useState, useEffect } from 'react';
import Spotlight from '../../components/spotlight/spotlight';
import { fetchContentfulEntries } from '../../services/contentfulAPI';
import './projects.scss';

const Projects = () => {
  const [projectsData, setProjectsData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { items } = await fetchContentfulEntries('project', 'fields.originalCreationDate');
        setProjectsData(items);
        setIsLoaded(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    document.documentElement.classList.add('projects');

    return () => {
      document.documentElement.classList.remove('projects');
      setProjectsData([]);
    };
  }, []);

  if (isLoading) {
    return <div className="loading">Loading Projects...</div>;
  }

  console.log("PROJECTS DATA FOR SPOTLIGHT",projectsData);

  return (
    <article className="projects">
      {projectsData.map((data) => (
        <Spotlight key={data.sys.id} data={data.fields} />
      )).reverse()}
    </article>
  );
};

export default Projects;