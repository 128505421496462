import React, { useEffect, useState } from 'react';
import { fetchContentfulEntries } from '../../services/contentfulAPI';
import { buildComponents } from '../../services/buildComponents';
import './home.scss';

const Home = (props) => {

  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    let isMounted = true; // flag to keep track of component mount state
  
    const fetchData = async () => {
      try {
        const contentfulPageEntries = await fetchContentfulEntries('page');
        const pageDataResponse = contentfulPageEntries.items.find(obj => obj.fields.slug === 'home');
  
        if (isMounted) { // only update state if component is still mounted
          setPageData(pageDataResponse);
        }
      } catch (error) {
        if (isMounted) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      isMounted = false; // cleanup toggles value, letting us short-circuit a setState
    };
  }, []);  

  return (
    <article className='home'>
      {buildComponents(pageData)}
    </article>
  );
}

export default Home;
